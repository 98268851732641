import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import BlogHeader from "../components/BlogHeader";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LineShareButton, LineIcon, PocketShareButton, PocketIcon } from "react-share";

export const BlogPostTemplate = ({ content, contentComponent, date, description, featuredimage, shareUrl, tags, title, pageTitle, helmet }) => {
  const PostContent = contentComponent || Content;
  return (
    <section className='section'>
      {helmet || ""}
      <div className='container content'>
        <div className='columns'>
          <div className='column is-10 is-offset-1'>
            <h1 className='heading'>{title}</h1>
            <div className='columns'>
              <div className='column is-one-third subtitle'>{date}</div>
              <div className='column'>
                {tags && tags.length ? (
                  <ul className='taglist mt-0 mr-0 is-justify-content-flex-end'>
                    {tags.map((tag) => (
                      <li key={tag + `tag`} className='mb-0 pb-1 pl-1 pr-0'>
                        <Link to={`/tags/${kebabCase(tag)}/`} className='button is-small'>
                          {tag}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `featured image thumbnail for post ${title}`,
              }}
            />
            <p>{description}</p>
            <PostContent content={content} />
            <aside className='mt-5'>
              <FacebookShareButton url={shareUrl} className='mr-2'>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={pageTitle} className='mr-2'>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
              <LineShareButton url={shareUrl} title={pageTitle} className='mr-2'>
                <LineIcon size={40} round />
              </LineShareButton>
              <PocketShareButton url={shareUrl} className='mr-2'>
                <PocketIcon size={40} round />
              </PocketShareButton>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  date: PropTypes.string,
  description: PropTypes.string,
  featuredimage: PropTypes.object,
  shareUrl: PropTypes.string,
  title: PropTypes.string,
  pageTitle: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post, site: site } = data;

  return (
    <Layout>
      <BlogHeader />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        description={post.frontmatter.description}
        featuredimage={post.frontmatter.featuredimage}
        helmet={
          <Helmet titleTemplate='%s | Blog'>
            <title>{`${post.frontmatter.title}`}</title>
            <meta name='description' content={`${post.frontmatter.description}`} />
            <meta property='og:title' content={`${post.frontmatter.title} - CONNECT BLOG | ${site.siteMetadata.title}`} />
            <meta property='og:image' content={site.siteMetadata.siteUrl + post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.src} />
            <meta property='og:url' content={encodeURI(site.siteMetadata.siteUrl + post.fields.slug)} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        pageTitle={`${post.frontmatter.title} - CONNECT BLOG | ${site.siteMetadata.title}`}
        shareUrl={encodeURI(site.siteMetadata.siteUrl + post.fields.slug)}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        description
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 100, layout: CONSTRAINED)
          }
        }
        tags
      }
    }
  }
`;
